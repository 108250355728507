.login-panel {
  max-width: 400px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.login-title {
  font-size: 24px;
}