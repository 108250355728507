@import "../../../scss/export.scss";

.icon-button {
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light-purple;
  border-radius: $border-radius;
  cursor: pointer;

  .icon {
    width: 20px;
    fill: $purple;
  }

  &.disabled { 
    opacity: 0.4;
    cursor: unset;
  }

  &.primary {
    background-color: $purple;

    .icon {
      fill: white;
    }
  }
}
