.panel {
  box-shadow: 3px 3px 25px 0px rgba(3, 0, 75, 0.03);
  background: white;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  width: 100%;
}

.panel-title {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #EDEEF2;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  line-height: 1.5rem;
  padding: 16px;
  font-size: 0.875rem;
}
