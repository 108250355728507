.departments-page {
  display: flex;

  .departments-menu {
    width: 300px;
    margin-right: 2rem;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

#add-icon {
  position: absolute;
  right: 10px;
}
