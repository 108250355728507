@import '../export.scss';

.badge {
  padding: 5px 15px;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 15px;
  color: white;
  margin-bottom: 1rem;
  background-color: $purple;

  .btn-close {
    margin-right: -0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
