@import '../export/mixins.scss';

$size: 'auto';

.mx-#{$size} {
    @include mx($size);
}

.ml-#{$size} {
    @include ml($size);
}

.mr-#{$size} {
    @include mr($size);
}

@each $size, $units in $spacing-sizes {
    // === Margin ===

    .m-#{$size} {
        @include m($size);
    }

    .mx-#{$size} {
        @include mx($size);
    }

    .my-#{$size} {
        @include my($size);
    }

    .ml-#{$size} {
        @include ml($size);
    }

    .mt-#{$size} {
        @include mt($size);
    }

    .mr-#{$size} {
        @include mr($size);
    }

    .mb-#{$size} {
        @include mb($size);
    }

    // === Padding ===

    .p-#{$size} {
        @include p($size);
    }

    .px-#{$size} {
        @include px($size);
    }

    .py-#{$size} {
        @include py($size);
    }

    .pl-#{$size} {
        @include pl($size);
    }

    .pt-#{$size} {
        @include pt($size);
    }

    .pr-#{$size} {
        @include pr($size);
    }

    .pb-#{$size} {
        @include pb($size);
    }

    .spread-#{$size} {
        display: flex;
    }

    .spread-#{$size} > div:last-child {
        @include mx(0);
    }

    .spread-#{$size} > div {
        @include mr($size);
    }
}