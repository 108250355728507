@import "../export.scss";

.caption {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}

.accent {
  color: $purple;
}

.bold {
  font-weight: 700;
}

.font-s {
  font-size: 0.8rem;
}

.error {
  font-size: 0.8rem;
  color: #f44336;
}

.link {
  font-size: 0.8rem;
  color: $purple;
}