.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.shrink-0 {
  flex-shrink: 0;
}

.j-start {
  justify-content: flex-start;
}

.j-center {
  justify-content: center;
}

.j-end {
  justify-content: flex-end;
}

.j-between {
  justify-content: space-between;
}

.a-start {
  align-items: flex-start;
}

.a-center {
  align-items: center;
}

.a-end {
  align-items: flex-end;
}