.edit-modal {
  .modal-content {
    display: flex;
    align-items: flex-end;

    button {
      margin: 0 10px 0 10px;
      height: 39px;
    }
  }
}