@import "./internal/table.scss";
@import "./internal/modal.scss";
@import "./internal/panel.scss";
@import "./internal/spacings.scss";
@import "./internal/grid.scss";
@import "./internal/typography.scss";
@import "./internal/badge.scss";
@import "./internal/stepper.scss";

.button-wrapper {
  position: relative;
  margin-right: 0.8rem;
  text-align: center;
}

.button-wrapper .label {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  font-size: 14px;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.actionsContainer {
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
}

.list-dot {
  display: inline-flex;
  align-self: center;
  background-color: $navbar-item-color;
  width: 5px;
  height: 5px;
  line-height: 3rem;
  border-radius: 50%;
  margin-right: 10px;
}

%purple-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $purple;
  color: white;

  svg {
    padding: 5px;
    fill: white;
  }
}

.dot {
  display: inline-block;
  margin:  0.2rem 3px 0.2rem 8px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: grey;
}

.accepted-circle {
  @extend %purple-circle;
  height: 20px;
  width: 20px;
}

.accordion-expand-button {
  @extend %purple-circle;
  height: 24px;
  width: 24px;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    padding: 6px;
  }

  &.expanded {
    svg {
      transform-origin: center center;
      transform: rotate(180deg);
    }
  }
}

a:-webkit-any-link {
  text-decoration: none;
}