@import '../export.scss';

.table-contaier {
  max-width: 1700px;
  width: 100%;
  margin: auto;
}

.list-item-active {
  color: $purple;
  font-weight: $font-bold;
}