@import '../../scss/export.scss';

$navbar-elements-color: $navbar-color;

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  color: $navbar-elements-color;
  padding: 20px;
  z-index: 1;

  .vertical-line {
    height: 50px;
    border-right: 1px solid $navbar-elements-color;
  }

  .logo {
    height: 60px;
    margin-right: 30px;

    img {
      height: 100%;
    }
  }

  .navbar {
    display: flex;

    .navbar-item {
      margin-right: 2rem;
      color: $navbar-elements-color;
      text-decoration: none;

      &.active {
        color: $navbar-elements-color;
        font-weight: 600;
      }
    }
  }

  .notifications {
    display: flex;
    align-items: center;
    margin: 0 30px 0 auto;

    .icon {
      margin: 0 30px;
      fill: $navbar-elements-color;
    }
  }

  .user {
    text-align: right;

    .username {
      margin-bottom: 6px;
      font-weight: $font-bold;
    }

    .logout {
      cursor: pointer;
    }
  }
}
