// $navbar-color: #32374A;
$navbar-color: #223F79;
// $navbar-item-color: #8B8F9B;
$navbar-item-color: white;

// $light-purple: #EAE2FA;
$light-purple: #ffc2c9;
// $purple: #7A4ADC;
$purple: #C20016;


$font-light: 300;
$font-regular: 400;
$font-bold: 600;

$border-radius: 4px;