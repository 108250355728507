@import '../export.scss';

.modal {
  position: absolute;
  background-color: white;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}

.modal-title {
  padding: 20px 30px;
  border-radius: 4px 4px 0 0;
  font-weight: $font-bold;
  background-color: $purple;
  color: white;
}

.modal-content {
  padding: 30px 40px;
}

.modal-m {
  width: 800px;
}