@import '../export.scss';

.step-container {
  position: relative;
  border-left: 1px solid #ccc;

  &:last-child {
    border-left: none;
  }
}

.step-number {
  position: absolute;
  margin-left: -15px;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  font-size: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $purple;
  color: white;
}