$spacing-sizes: (
    0: 0rem,
    1: 0.25rem,
    2: 0.5rem,
    3: 1rem,
    4: 1.5rem,
    5: 2rem,
    6: 4rem,
    7: 8rem,
    auto: auto,
) !default;

$spacing-important: true !default;

@mixin btn-circle($size) {
    border-radius: 50%;
    height: $size;
    width: $size;
    line-height: $size;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin m($t: 1, $r: null, $b: null, $l: null) {
    @if ($t and $r and $b and $l) {
        @include mt($t);
        @include mr($r);
        @include mb($b);
        @include ml($l);
    } @else if ($t and $r and $b and not $l) {
        @include mt($t);
        @include mx($r);
        @include mb($b);
    } @else if ($t and $r and not $b and not $l) {
        @include my($t);
        @include mx($r);
    } @else if ($t and not $r and not $b and not $l) {
        @if ($t== 'auto') {
            @include my(0);
            @include mx(auto);
        } @else {
            @include my($t);
            @include mx($t);
        }
    } @else {
        @error "m(#{$t}, #{$r}, #{$b}, #{$l}): Invalid parameters. Expects m($size), m($y, $x), m($t, $x, $b) or m($t, $r, $b, $l)";
    }
}

@mixin mx($r: 1, $l: null) {
    @if ($r and $l) {
        @include mr($r);
        @include ml($l);
    } @else if ($r and not $l) {
        @include mr($r);
        @include ml($r);
    } @else {
        @error "mx(#{$r}, #{$l}): Invalid parameters. Expects mx($size) or mx($r, $l)";
    }
}

@mixin my($t: 1, $b: null) {
    @if ($t and $b) {
        @include mt($t);
        @include mb($b);
    } @else if ($t and not $b) {
        @include mt($t);
        @include mb($t);
    } @else {
        @error "my(#{$t}, #{$b}): Invalid parameters. Expects my($size) or my($t, $b)";
    }
}

@mixin ml($size: 1) {
    @if ($size== 'auto') {
        margin-left: auto #{if($spacing-important, '!important', '')};
    } @else {
        margin-left: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
    }
}

@mixin mt($size: 1) {
    margin-top: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
}

@mixin mr($size: 1) {
    @if ($size== 'auto') {
        margin-right: auto #{if($spacing-important, '!important', '')};
    } @else {
        margin-right: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
    }
}

@mixin mb($size: 1) {
    margin-bottom: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
}

@mixin p($t: 1, $r: null, $b: null, $l: null) {
    @if ($t and $r and $b and $l) {
        @include pt($t);
        @include pr($r);
        @include pb($b);
        @include pl($l);
    } @else if ($t and $r and $b and not $l) {
        @include pt($t);
        @include px($r);
        @include pb($b);
    } @else if ($t and $r and not $b and not $l) {
        @include py($t);
        @include px($r);
    } @else if ($t and not $r and not $b and not $l) {
        @include py($t);
        @include px($t);
    } @else {
        @error "p(#{$t}, #{$r}, #{$b}, #{$l}): Invalid parameters. Expects p($size), p($y, $x), p($t, $x, $b) or p($t, $r, $b, $l)";
    }
}

@mixin px($r: 1, $l: null) {
    @if ($r and $l) {
        @include pr($r);
        @include pl($l);
    } @else if ($r and not $l) {
        @include pr($r);
        @include pl($r);
    } @else {
        @error "px(#{$r}, #{$l}): Invalid parameters. Expects px($size) or px($r, $l)";
    }
}

@mixin py($t: 1, $b: null) {
    @if ($t and $b) {
        @include pt($t);
        @include pb($b);
    } @else if ($t and not $b) {
        @include pt($t);
        @include pb($t);
    } @else {
        @error "py(#{$t}, #{$b}): Invalid parameters. Expects py($size) or py($t, $b)";
    }
}

@mixin pl($size: 1) {
    padding-left: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
}

@mixin pt($size: 1) {
    padding-top: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
}

@mixin pr($size: 1) {
    padding-right: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
}

@mixin pb($size: 1) {
    padding-bottom: map-get($spacing-sizes, $size) #{if($spacing-important, '!important', '')};
}

@mixin spread($size: 1) {
    display: flex;

    div:last-child {
        @include mx(0);
    }

    div {
        @include mr($size);
    }
}
